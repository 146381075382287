@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body{
    background-color: rgb(245, 245, 245);
    
}
.news-item{
    background-color: rgba(255, 245, 234, 0.75);
    color: #000;
    width: 450px;
    min-width: 300px;
    min-height: 292px;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    margin: 20px;
    text-decoration: none;

}
.news-item:hover{
    background-color: rgba(0, 32, 96, 0.8);
    color: white;
}
.carousel-caption{
    background-color: rgba(255, 245, 234, 0.75);
    border-radius: 20px;
}
.news-header{
    background-color: rgba(255, 245, 234, 0.75);
    max-width: 500px;
}
div.news-container{
    background-color: rgba(0, 0, 0, .3);
    background-image: url("./images/imgNews.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}
div.bg-expand{
    background-color: antiquewhite;
    background-image: url("./images/imgForm.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 150px;
    padding-bottom: 100px;
}
.news-header{
    margin: auto;
}
.news-content{
    margin-top: 16px;
}

img.cover{
    object-fit: cover;
    min-width: 100%;
    max-height: 400px;
    min-height: 300px;
    object-position: center;
    
}
.primary-text{
    color: rgb(0, 32, 96);
}
.secondary-text{
    color: rgb(205, 0, 0);
}
.alert-warning{
    background: rgb(245, 64, 64);
    color: #FFF;
    
}
.team-item {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15) !important;
    border-radius: 5px !important;
}

.static-contact{
	position:fixed;
	width:90px;
	height:90px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50%;
	text-align:center;
  font-size:45px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.static-contact:hover{
    color: rgb(219, 219, 219);
}
.icon-contact{
	margin-top:23px;
}
.icon-contact-fixed{
    margin-top: 27px;
}
.icon{
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
    margin: 1.5rem;
}
.icon:hover{
    color: rgb(219, 219, 219);
}
.icon-whatsapp{
    background: #25d366;
    color: #FFF;
}
.icon-instagram{
    background: #125688;
    color: white;
}
.icon-linkedin{
    background: #007bb5;
    color: white;
}

.primary-btn{
    background-color: white;
    color: rgb(0, 32, 96);
    border: 1px solid rgb(0, 32, 96);
}
.primary-btn:hover{
    background-color: rgb(0, 32, 96);
    color: white;
}
.secondary-btn{
    background-color: white;
    color: rgb(205, 0, 0);
    border: 1px solid rgb(205, 0, 0);
}
.secondary-btn:hover{
    background-color: rgb(205, 0, 0);
    color: white;
} 
.nav-link{
    color: rgb(0, 32, 96) !important;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002060'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23002060'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
.carousel-indicators [data-bs-target] {
    background-color: rgb(0, 32, 96);
}
@media screen and (max-width: 425px){
    
    .carousel-caption h1{
        font-size: 37px !important;
    }
    .carousel-caption p{
        font-size: 27px !important;
    }
}
@media (max-width: 320px){

    .carousel-caption h1{
        font-size: 30px !important;
    }
    .carousel-caption p{
        font-size: 20px !important;
    }
}
@media screen and (max-width: 467px){
    .icon{
        font-size: 45px;
        max-width: 85px;
        max-height: 85px;
        margin:auto;
    }
    .icon-contact-fixed{
        margin-top: 21px;
    }
    
}